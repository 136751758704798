"use strict"

import * as universal from '@/lib/core/universal'
import * as jsf from '@/lib/js_functions'
export class Visitas {
  updateURLsImagensPoligonos({ FormLines, register }) {
    if (!register.poligono) return
    register.poligono.map(async poligono => {
      if (typeof poligono === 'object' && !Array.isArray(poligono)) {
        const foto = register.fotos?.find(foto => foto.id == poligono.info?.id)
        if (foto) {
          await this.getImage({ FormLines, _id: register._id, fileName: foto.foto })
          poligono.info.foto = this.foto
        }
      }
    })
  }
  checkIfHasLatLng(vmodel) {
    if (vmodel.mostrarNoMapa != 'Sim' || !vmodel.lat || !vmodel.lng) { return true }
    else { return false }
  }
  checkIfHasNoMetadados({ metadados, FormLines, vmodel }) {
    if (FormLines.$store.state.alert.text === "Ocorreu um problema ao salvar os arquivos: Espaço de armazenamento insuficiente.") return true
    if (!metadados) {
      FormLines.$store.commit("showAlert", { type: 'warning', text: 'Foto sem metadados', dismissible: true })
      return true
    }
    if (!metadados?.latitude || !metadados?.longitude) {
      this.setLatLng({ FormLines, vmodel, id: vmodel.id })
      FormLines.$store.commit("showAlert", { type: 'warning', text: 'Foto sem localização', dismissible: true })
      return true
    }
    return false
  }
  async #getTerreno({ action, query, project }) { return (await jsf.getFunc({ func: () => universal.getGenericRegisters({ dataId: "terrenos", action, query, project }), returnData: true })).data }
  async setPoligonoTerrenoOnFieldMap({ FormLines, register }) {
    const terreno = await this.#getTerreno({ action: 'read/one', query: { _id: register.terreno }, project: { poligono: 1 } })
    this.setPoligonoOnFieldMap({ FormLines, register, terreno })
  }
  setPoligonoOnFieldMap({ FormLines, register, terreno }) {
    if (terreno?.poligono) { register.poligono = terreno.poligono }
    else {
      FormLines.register.poligono = []
      FormLines.$store.commit("showError", { data: 'Nenhum polígono encontrado!' })
    }
  }
  setId({ lines }) {
    const nextId = this.findNextId(lines)
    lines[lines.length - 1].id = nextId
  }
  updateLabelMarker({ FormLines, vmodel }) {
    if (this.checkIfHasLatLng(vmodel)) return
    this.removeMarkerMap({ register: FormLines.register, id: vmodel.id })
    this.addMarkerMap({ FormLines, vmodel })
  }
  setInfosMarker({ register, vmodel, id, partOfMarker, content }) {
    if (this.checkIfHasLatLng(vmodel)) return
    const index = this.findLineNumberMarker(register, id)
    register.poligono[index].info[partOfMarker] = content
  }
  async addMarkerMap({ FormLines, vmodel }) {
    const coords = { lat: jsf.numberIn(vmodel.lat), lng: jsf.numberIn(vmodel.lng) }
    await this.getImage({ FormLines, _id: FormLines.register._id, fileName: vmodel.foto })
    FormLines.register.poligono.push({ poligono: [coords], info: { id: vmodel.id, titulo: vmodel.nome, informacao: vmodel.informacao, label: vmodel.labelMarcador, foto: this.foto } })
  }
  removeMarkerMap({ register, id }) {
    const index = this.findLineNumberMarker(register, id)
    if (index != -1) { register.poligono.splice(index, 1) }
  }
  findLineNumberMarker(register, id) {
    const findId = (element => { if (element.poligono?.length == 1) { return element.info.id == id ? true : false } })
    return register.poligono.findIndex(findId)
  }
  addMarkerOnMapAndLineOnFotos({ register, infosMap, lines }) {
    const coord = infosMap.latLng
    const nextId = this.findNextId(lines)
    register.fotos.push({ id: nextId, labelMarcador: '', nome: '', informacao: '', mostrarNoMapa: "Sim", lat: coord.lat().toString(), lng: coord.lng().toString() })
    register.poligono.push({ poligono: [{ lat: coord.lat(), lng: coord.lng() }], info: { id: nextId, titulo: '', informacao: '', label: '' } })
  }
  findNextId(lines) {
    if (lines.length <= 0) { return '1' }
    const biggestID = lines.reduce((maior, line) => (jsf.numberIn(line.id) > jsf.numberIn(maior.id) ? line : maior)).id
    return jsf.numberOut(jsf.numberIn(biggestID) + 1)
  }
  setLatLng({ FormLines, vmodel, id }) {
    if (this.checkIfHasLatLng(vmodel)) return
    const index = this.findLineNumberMarker(FormLines.register, id)
    if (index == -1) { return this.addMarkerMap({ FormLines, vmodel }) }
    FormLines.register.poligono[index].poligono[0].lat = vmodel.lat
    FormLines.register.poligono[index].poligono[0].lng = vmodel.lng
    this.removeMarkerMap({ register: FormLines.register, id })
    this.addMarkerMap({ FormLines, vmodel })
  }
  setCoordsInLine({ vmodel, lat, lng }) {
    vmodel.lat = `${lat}`
    vmodel.lng = `${lng}`
  }
  async getImage({ FormLines, _id, fileName }) {
    const params = { module: 'data', dataId: 'visitas', _id, folder: 'fotos', fileName, early: true }
    if (!await jsf.getFunc({ FormLines, receiver: { object: this, name: 'foto' }, func: () => FormLines.Form.storage.downloadFile(params) })) { return }
  }
}